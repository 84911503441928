<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Product</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="product-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-text-field
            label="Slug *"
            v-model="slug"
            @change="onChange($event)"
            type="text"
            outlined
            dense
            background-color="white"
            :error="errors.hasOwnProperty('slug')"
            :error-messages="errors['slug']"
          ></v-text-field>

          <tiptap
            v-model="fields.description"
            label="Description"
            class="mb-8"
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          />

          <v-file-input
            v-model="fields.image"
            accept="image/png, image/jpeg"
            prepend-icon="mdi-camera"
            label="Main Image"
            outlined
            background-color="white"
            @change="imageHasChanged()"
            :error="errors.hasOwnProperty('image')"
            :error-messages="errors['image']"
          ></v-file-input>
          <v-select
            label="Category"
            v-model="fields.category_id"
            :items="categories"
            item-text="name"
            item-value="id"
            outlined
            @change="documentHasChanged()"
            background-color="white"
            :error="errors.hasOwnProperty('category_id')"
            :error-messages="errors['category_id']"
          ></v-select>

          <v-text-field
            label="Price (£) *"
            v-model="fields.price"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('price')"
            :error-messages="errors['price']"
          ></v-text-field>

          <v-select
            label="Status"
            v-model="fields.status"
            :items="statuses"
            background-color="white"
            outlined
            persistent-hint
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          :disabled="productLoading"
          form="product-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
  },

  data() {
    return {
      media: {
        saved: [],
        added: [],
        removed: [],
      },
      dialog: false,
      loading: false,
      productLoading: false,
      isEditing: false,

      product: {},

      statuses: [
        { text: "Draft", value: "draft" },
        { text: "On Hold", value: "on-hold" },
        { text: "Online", value: "online" },
        { text: "Offline", value: "offline" },
      ],

      fields: {
        name: null,
        slug: null,
        status: "draft",
        price: null,
        description: null,
        category_id: null,
        image: null,
        image_has_changed: false,
        document_has_changed: false,
      },
      errors: {},
    };
  },

  computed: {
    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.name;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },

    categories() {
      return this.$store.getters["craigtoun/productsStore/categories"];
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    onChange(event) {
      this.fields.slug = event;
    },
    openForm(productId = null) {
      if (productId !== null) {
        this.isEditing = true;
        this.productLoading = true;

        this.$store
          .dispatch("craigtoun/productsStore/loadProduct", {
            appId: this.$route.params.id,
            productId,
          })
          .then((product) => {
            this.product = product;

            this.fields.name = product.name;
            this.fields.slug = product.slug;

            this.fields.short_description = product.short_description;
            this.fields.description = product.description;

            this.fields.price = product.price;

            this.fields.category = product.category.id;

            this.fields.image = product.image
              ? new File([product.image], product.image)
              : null;
            //   this.fields.categories = product.categories.map((c) => c.id);

            // this.$refs.priceForm.fields.prices = product.sizes;

            // product.downloads.forEach((values, keys) => {
            //   product.downloads[keys].changed = false;
            //   product.downloads[keys].file = new File(
            //     [values.file],
            //     values.file
            //   );
            // });

            // this.$refs.downloadForm.fields.downloads = product.downloads;

            // this.$refs.fileUpload.setImages(product.images, true);

            this.fields.include_vat = product.include_vat;
            this.fields.type = product.type;
            //  this.fields.max_purchase_quantity = product.max_purchase_quantity;
            this.fields.sub_title = product.sub_title;
            // this.fields.can_purchase = product.can_purchase;

            this.productLoading = false;
          });
      }

      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.product = {};
      this.errors = {};

      this.fields.name = null;
      this.fields.slug = null;
      this.fields.status = "draft";
      this.fields.price = null;
      this.fields.description = null;
      this.fields.category_id = null;
      this.fields.image = null;
      this.fields.thumbnail_has_changed = false;
      this.fields.image_has_changed = false;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.productId = this.product.id;
      }

      this.$store
        .dispatch("craigtoun/productsStore/saveProduct", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;

          this.loading = false;
        });
    },

    imageHasChanged() {
      this.fields.image_has_changed = true;
    },
    documentHasChanged() {
      this.fields.document_has_changed = true;
    },
  },
};
</script>
