<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h2>Products</h2>
        </v-col>
        <v-col cols="6" class="pl-12">
          <v-text-field
            label="Search Products"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="warning"
                  class="mr-2"
                  v-on="on"
                  :to="{ name: 'module-craigtoun-product-categories' }"
                >
                  <v-icon small>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <span>View Categories</span>
            </v-tooltip>

            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="info"
                  class="mr-2"
                  v-on="on"
                  :to="{ name: 'module-craigtoun-product-brands' }"
                >
                  <v-icon small>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <span>View Brands</span>
            </v-tooltip> -->

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.productForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Product</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: 'module-craigtoun-product-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Products</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="products"
        no-data-text="There are currently no products"
      >
        <template v-slot:item.image="{ item }">
          <v-img
            v-if="item.main_image"
            width="100"
            :src="item.main_image.asset_urls.url"
            aspect-ratio="16/9"
            cover
            class="white--text align-end pb-4 pt-4"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
          </v-img>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip
            label
            small
            v-if="item.status === 'draft'"
            color="blue"
            text-color="white"
            >Draft</v-chip
          >
          <v-chip
            label
            small
            v-else-if="item.status === 'on-hold'"
            color="amber"
            >On Hold</v-chip
          >
          <v-chip label small color="green" v-else-if="item.status === 'online'"
            >Online</v-chip
          >
          <v-chip label small v-else-if="item.status === 'offline'" color="red"
            >Offline</v-chip
          >
        </template>

        <template v-slot:item.price="{ item }">
          £{{ formatPrice(item.price) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                class="mr-2"
                :to="{
                  name: 'module-craigtoun-products-individual',
                  params: { productId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Product</span>
          </v-tooltip>

          <!-- <v-tooltip bottom>

            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.productForm.openForm(item.id)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDeleteProduct(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <ProductForm ref="productForm" />
    <!-- <TagsForm ref="tagsForm" /> -->
    <v-dialog v-model="deleteProduct.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Product</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteProduct.product.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteProduct"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteProduct.loading"
            @click="saveDeleteProduct"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductForm from "./components/ProductForm";
// import TagsForm from "./components/TagsForm";

export default {
  components: {
    ProductForm,
    // TagsForm,
  },

  data() {
    return {
      searchTerm: "",
      searchType: null,
      searchFormName: "",
      breadcrumbs: [
        {
          text: "craigtoun",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Products",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Image", value: "image" },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "Category", value: "category.name", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteProduct: {
        dialog: false,
        product: {},
        loading: false,
      },
    };
  },

  filters: {},

  computed: {
    products() {
      let products = this.$store.getters["craigtoun/productsStore/all"];

      if (this.searchTerm !== "") {
        products = products.filter((p) => {
          const name = p.name.toLowerCase();

          return name.includes(this.searchTerm.toLowerCase());
        });
      }

      return products;
    },
  },

  methods: {
    openDeleteProduct(product) {
      this.deleteProduct.product = product;
      this.deleteProduct.dialog = true;
    },

    resetDeleteProduct() {
      this.deleteProduct.dialog = false;
      this.deleteProduct.product = {};
      this.deleteProduct.loading = false;
    },

    saveDeleteProduct() {
      this.deleteProduct.loading = true;

      this.$store
        .dispatch("craigtoun/productsStore/deleteProduct", {
          appId: this.$route.params.id,
          productId: this.deleteProduct.product.id,
        })
        .then(() => {
          this.resetDeleteProduct();
        })
        .catch(() => {
          this.deleteProduct.loading = false;
        });
    },
  },
};
</script>
